
import styled from 'styled-components';
import Text from '../text';

const BodyThin = styled(Text)`
  font-family: Lato;
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 1.5;
`;

export {BodyThin};
