import { HTMLAttributes, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import Colors from '../../../../assets/Colors';
import { TextPresets } from '../../text';

const StyledGridIcon = styled.div`
  color: ${Colors.Grayscale.FullLight};
  background: ${({backgroundColor}:GridIconProps)=>(backgroundColor)?backgroundColor:''};
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  filter: drop-shadow(7px 7px 30px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface GridIconProps extends HTMLAttributes<HTMLDivElement> {
  Content?: string | ReactNode;
  backgroundColor?: string;
}

export const GridIcon = ({Content, ...props}: GridIconProps) => {
  const FormattedContent = useMemo(() => {
    if (!Content) {
      return null;
    }

    if (typeof Content === 'string') {
      return (
        <TextPresets.BodyLight as='div' className='_Content'>
          {Content}
        </TextPresets.BodyLight>
      );
    }

    return Content;
  }, [Content]);
  return (
    <StyledGridIcon {...props}>
      {FormattedContent}
    </StyledGridIcon>
  );
};
