import _21buttons from '../assets/platforms/21buttons.png';
import adyen from '../assets/platforms/adyen.png';
import affiliatelink from '../assets/platforms/affiliatelink.png';
import amazon from '../assets/platforms/amazon.png';
import applemusic from '../assets/platforms/applemusic.png';
import contact from '../assets/platforms/contact.png';
import asana from '../assets/platforms/asana.png';
import phone from '../assets/platforms/phone.png';
import email from '../assets/platforms/email.png';
import whatsapp from '../assets/platforms/whatsapp.png';
import address from '../assets/platforms/address.png';
import signal from '../assets/platforms/signal.png';
import telegram from '../assets/platforms/telegram.png';
import instagram from '../assets/platforms/instagram.png';
import linkedin from '../assets/platforms/linkedin.png';
import bereal from '../assets/platforms/bereal.png';
import twitter from '../assets/platforms/twitter.png';
import tiktok from '../assets/platforms/tiktok.png';
import pinterest from '../assets/platforms/pinterest.png';
import snapchat from '../assets/platforms/snapchat.png';
import twitch from '../assets/platforms/twitch.png';
import reddit from '../assets/platforms/reddit.png';
import discord from '../assets/platforms/discord.png';
import clubhouse from '../assets/platforms/clubhouse.png';
import facebook from '../assets/platforms/facebook.png';
import youtube from '../assets/platforms/youtube.png';
import spotify from '../assets/platforms/spotify.png';
import soundcloud from '../assets/platforms/soundcloud.png';
import vimeo from '../assets/platforms/vimeo.png';
import ivoox from '../assets/platforms/ivoox.png';
import web from '../assets/platforms/web.png';
import whatsappbusiness from '../assets/platforms/whatsappbusiness.png';
import calendly from '../assets/platforms/calendly.png';
import googledrive from '../assets/platforms/googledrive.png';
import dropbox from '../assets/platforms/dropbox.png';
import googlecalendar from '../assets/platforms/googlecalendar.png';
import curriculum from '../assets/platforms/curriculum.png';
import slack from '../assets/platforms/slack.png';
import monday from '../assets/platforms/monday.png';
import confluence from '../assets/platforms/confluence.png';
import trello from '../assets/platforms/trello.png';
import zoom from '../assets/platforms/zoom.png';
import skype from '../assets/platforms/skype.png';
import googlemeet from '../assets/platforms/googlemeet.png';
import googlereviews from '../assets/platforms/googlereviews.png';
import microsoftteams from '../assets/platforms/microsoftteams.png';
import googlehangouts from '../assets/platforms/googlehangouts.png';
import evernote from '../assets/platforms/evernote.png';
import notion from '../assets/platforms/notion.png';
import appstore from '../assets/platforms/appstore.png';
import playstore from '../assets/platforms/playstore.png';
import medium from '../assets/platforms/medium.png';
import wetransfer from '../assets/platforms/wetransfer.png';
import eventbrite from '../assets/platforms/eventbrite.png';
import shopify from '../assets/platforms/shopify.png';
import etsy from '../assets/platforms/etsy.png';
import paypal from '../assets/platforms/paypal.png';
import stripe from '../assets/platforms/stripe.png';
import revolut from '../assets/platforms/revolut.png';
import typeform from '../assets/platforms/typeform.png';
import itcloset from '../assets/platforms/itcloset.png';
import vsco from '../assets/platforms/vsco.png';
import vinted from '../assets/platforms/vinted.png';
import peoople from '../assets/platforms/peoople.png';
import opensea from '../assets/platforms/opensea.png';
import behance from '../assets/platforms/behance.png';
import dribbble from '../assets/platforms/dribbble.png';
import onlyfans from '../assets/platforms/onlyfans.png';
import flipboard from '../assets/platforms/flipboard.png';
import tumblr from '../assets/platforms/tumblr.png';
import rarible from '../assets/platforms/rarible.png';
import menu from '../assets/platforms/menu.png';
import discountcode from '../assets/platforms/discountcode.png';
import micolet from '../assets/platforms/micolet.png';
import customlink from '../assets/platforms/customlink.png';
import { TFunction } from 'i18next';

type SocialPlatformInputType = 'link' | 'username' | 'phone' | 'address' | 'contact' | 'email';

interface SocialPlatformParams {
  t: TFunction;
  platformType: string;
  inputType: SocialPlatformInputType;
  priority: number;
  placeholder: string;
  beforeText: string;
  image: string;
  name: string;
  recommended: boolean
}

export class SocialPlatform {
  private t: TFunction;
  public platformType: string;
  public inputType: SocialPlatformInputType;
  public label: string;
  public priority: number;
  public placeholder: string;
  public beforeText: string;
  public image: string;
  public name: string
  public recommended: boolean;

  constructor(params: SocialPlatformParams) {
    this.platformType = params.platformType;
    this.inputType = params.inputType;
    this.t = params.t;
    this.priority = params.priority;
    this.placeholder = params.placeholder;
    this.image = params.image;
    this.beforeText = params.beforeText;
    this.label = this.t(`selectors.platform.${params.platformType}`);
    this.name = params.name;
    this.recommended = params.recommended;
  }
}

interface PlatformOptionsParams {
  t: TFunction;
}

export const PLATFORM_OPTIONS = ({t}: PlatformOptionsParams) => {
  return [
    new SocialPlatform({
      t,
      inputType: 'phone',
      platformType: 'phone',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.phone,
      placeholder: 'Número de teléfono',
      priority: 5,
      name: "Teléfono",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'email',
      platformType: 'email',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.email,
      placeholder: 'Correo electrónico',
      priority: 5,
      name: "Email",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'phone',
      platformType: 'whatsapp',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.whatsapp,
      placeholder: 'Número de teléfono',
      priority: 5,
      name: "Whatsapp",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'address',
      platformType: 'address',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.address,
      placeholder: 'Calle, avenida, plaza...',
      priority: 5,
      name: "Dirección",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'signal',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.signal,
      placeholder: 'Enlace',
      priority: 5,
      name: "Signal",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'telegram',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.telegram,
      placeholder: 'usuario',
      priority: 5,
      name: "Telegram",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'instagram',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.instagram,
      placeholder: 'usuario',
      priority: 5,
      name: "Instagram",
      recommended: true,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'linkedin',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.linkedin,
      placeholder: 'usuario',
      priority: 5,
      name: "Linkedin",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'bereal',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.bereal,
      placeholder: 'Enlace',
      priority: 5,
      name: "BeReal",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'twitter',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.twitter,
      placeholder: 'usuario',
      priority: 5,
      name: "Twitter",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'tiktok',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.tiktok,
      placeholder: 'usuario',
      priority: 5,
      name: "TikTok",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'pinterest',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.pinterest,
      placeholder: 'usuario',
      priority: 5,
      name: "Pinterest",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'snapchat',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.snapchat,
      placeholder: 'usuario',
      priority: 5,
      name: "Snapchat",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'contact',
      platformType: 'contact',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.contact,
      placeholder: 'Nombre de contacto',
      priority: 5,
      name: "Contacto",
      recommended: true,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'twitch',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.twitch,
      placeholder: 'Enlace',
      priority: 5,
      name: "Twitch",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'reddit',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.reddit,
      placeholder: 'Enlace',
      priority: 5,
      name: "Reddit",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'discord',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.discord,
      placeholder: 'Enlace',
      priority: 5,
      name: "Discord",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'clubhouse',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.clubhouse,
      placeholder: 'Enlace',
      priority: 5,
      name: "Clubhouse",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'username',
      platformType: 'facebook',
      beforeText: '@',
      image: PLATFORM_IMAGES_OPTIONS.facebook,
      placeholder: 'usuario',
      priority: 5,
      name: "Facebook",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'youtube',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.youtube,
      placeholder: 'Enlace',
      priority: 5,
      name: "Youtube",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'spotify',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.spotify,
      placeholder: 'Enlace',
      priority: 5,
      name: "Spotify",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'applemusic',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.applemusic,
      placeholder: 'Enlace',
      priority: 5,
      name: "Apple Music",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'soundcloud',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.soundcloud,
      placeholder: 'Enlace',
      priority: 5,
      name: "Soundcloud",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'vimeo',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.vimeo,
      placeholder: 'Enlace',
      priority: 5,
      name: "Vimeo",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'ivoox',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.ivoox,
      placeholder: 'Enlace',
      priority: 5,
      name: "Ivoox",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'web',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.web,
      placeholder: 'Enlace',
      priority: 5,
      name: "Web",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'whatsappbusiness',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.whatsappbusiness,
      placeholder: 'Enlace',
      priority: 5,
      name: "Whatsapp Business",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'calendly',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.calendly,
      placeholder: 'Enlace',
      priority: 5,
      name: "Calendly",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'googledrive',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.googledrive,
      placeholder: 'Enlace',
      priority: 5,
      name: "Google drive",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'dropbox',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.dropbox,
      placeholder: 'Enlace',
      priority: 5,
      name: "Dropbox",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'googlecalendar',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.googlecalendar,
      placeholder: 'Enlace',
      priority: 5,
      name: "Google calendar",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'curriculum',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.curriculum,
      placeholder: 'Enlace',
      priority: 5,
      name: "Curriculum",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'slack',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.slack,
      placeholder: 'Enlace',
      priority: 5,
      name: "Slack",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'monday',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.monday,
      placeholder: 'Enlace',
      priority: 5,
      name: "Monday",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'asana',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.asana,
      placeholder: 'Enlace',
      priority: 5,
      name: "Asana",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'confluence',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.confluence,
      placeholder: 'Enlace',
      priority: 5,
      name: "Confluence",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'trello',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.trello,
      placeholder: 'Enlace',
      priority: 5,
      name: "Trello",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'zoom',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.zoom,
      placeholder: 'Enlace',
      priority: 5,
      name: "Zoom",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'skype',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.skype,
      placeholder: 'Enlace',
      priority: 5,
      name: "Skype",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'googlemeet',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.googlemeet,
      placeholder: 'Enlace',
      priority: 5,
      name: "Google Meet",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'googlereviews',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.googlereviews,
      placeholder: 'Enlace',
      priority: 5,
      name: "Google Reviews",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'microsoftteams',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.microsoftteams,
      placeholder: 'Enlace',
      priority: 5,
      name: "Microsoft Teams",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'googlehangouts',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.googlehangouts,
      placeholder: 'Enlace',
      priority: 5,
      name: "Google Hangouts",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'evernote',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.evernote,
      placeholder: 'Enlace',
      priority: 5,
      name: "Evernote",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'notion',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.notion,
      placeholder: 'Enlace',
      priority: 5,
      name: "Notion",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'appstore',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.appstore,
      placeholder: 'Enlace',
      priority: 5,
      name: "AppLink",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'playstore',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.playstore,
      placeholder: 'Enlace',
      priority: 5,
      name: "Play Store",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'medium',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.medium,
      placeholder: 'Enlace',
      priority: 5,
      name: "Médium",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'wetransfer',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.wetransfer,
      placeholder: 'Enlace',
      priority: 5,
      name: "We Transfer",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'eventbrite',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.eventbrite,
      placeholder: 'Enlace',
      priority: 5,
      name: "Eventbrite",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'shopify',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.shopify,
      placeholder: 'Enlace',
      priority: 5,
      name: "Shopify",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'amazon',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.amazon,
      placeholder: 'Enlace',
      priority: 5,
      name: "Amazon",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'etsy',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.etsy,
      placeholder: 'Enlace',
      priority: 5,
      name: "Etsy",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'paypal',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.paypal,
      placeholder: 'Enlace',
      priority: 5,
      name: "PayPal",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'stripe',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.stripe,
      placeholder: 'Enlace',
      priority: 5,
      name: "Stripe",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'revolut',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.revolut,
      placeholder: 'Enlace',
      priority: 5,
      name: "Revolut",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'adyen',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.adyen,
      placeholder: 'Enlace',
      priority: 5,
      name: "Adyen",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'typeform',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.typeform,
      placeholder: 'Enlace',
      priority: 5,
      name: "TypeForm",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'itcloset',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.itcloset,
      placeholder: 'Enlace',
      priority: 5,
      name: "ItCloset",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'vsco',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.vsco,
      placeholder: 'Enlace',
      priority: 5,
      name: "Vsco",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: '21buttons',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS['21buttons'],
      placeholder: 'Enlace',
      priority: 5,
      name: "21 buttons",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'vinted',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.vinted,
      placeholder: 'Enlace',
      priority: 5,
      name: "Vinted",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'peoople',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.peoople,
      placeholder: 'Enlace',
      priority: 5,
      name: "Peoople",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'opensea',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.opensea,
      placeholder: 'Enlace',
      priority: 5,
      name: "Open Sea",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'behance',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.behance,
      placeholder: 'Enlace',
      priority: 5,
      name: "Behance",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'dribbble',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.dribbble,
      placeholder: 'Enlace',
      priority: 5,
      name: "Dribbble",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'onlyfans',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.onlyfans,
      placeholder: 'Enlace',
      priority: 5,
      name: "Onlyfans",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'flipboard',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.flipboard,
      placeholder: 'Enlace',
      priority: 5,
      name: "Flipboard",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'tumblr',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.tumblr,
      placeholder: 'Enlace',
      priority: 5,
      name: "Tumblr",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'rarible',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.rarible,
      placeholder: 'Enlace',
      priority: 5,
      name: "Rarible",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'menu',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.menu,
      placeholder: 'Enlace',
      priority: 5,
      name: "Menú",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'discountcode',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.discountcode,
      placeholder: 'Enlace',
      priority: 5,
      name: "Código Descuento",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'micolet',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.micolet,
      placeholder: 'Enlace',
      priority: 5,
      name: "Micolet",
      recommended: false,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'customlink',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.customlink,
      placeholder: 'Enlace',
      priority: 5,
      name: "Personalizado",
      recommended: true,
    }),
    new SocialPlatform({
      t,
      inputType: 'link',
      platformType: 'affiliateLink',
      beforeText: '',
      image: PLATFORM_IMAGES_OPTIONS.affiliateLink,
      placeholder: 'Enlace',
      priority: 5,
      name: "Link Afiliado",
      recommended: false,
    }),
  ].sort((a, b) => {
    if (a.priority !== b.priority) {
      return a.priority - b.priority;
    }

    return b.label.localeCompare(a.label);
  });
};

export const PLATFORM_IMAGES_OPTIONS = {
  phone: phone,
  email: email,
  whatsapp: whatsapp,
  address: address,
  signal: signal,
  telegram: telegram,
  instagram: instagram,
  linkedin: linkedin,
  bereal: bereal,
  twitter: twitter,
  tiktok: tiktok,
  pinterest: pinterest,
  snapchat: snapchat,
  contact: contact,
  twitch: twitch,
  reddit: reddit,
  discord: discord,
  clubhouse: clubhouse,
  facebook: facebook,
  youtube: youtube,
  spotify: spotify,
  applemusic: applemusic,
  soundcloud: soundcloud,
  vimeo: vimeo,
  ivoox: ivoox,
  web: web,
  whatsappbusiness: whatsappbusiness,
  calendly: calendly,
  googledrive: googledrive,
  dropbox: dropbox,
  googlecalendar: googlecalendar,
  curriculum: curriculum,
  slack: slack,
  monday: monday,
  asana: asana,
  confluence: confluence,
  trello: trello,
  zoom: zoom,
  skype: skype,
  googlemeet: googlemeet,
  googlereviews: googlereviews,
  microsoftteams: microsoftteams,
  googlehangouts: googlehangouts,
  evernote: evernote,
  notion: notion,
  appstore: appstore,
  playstore: playstore,
  medium: medium,
  wetransfer: wetransfer,
  eventbrite: eventbrite,
  shopify: shopify,
  amazon: amazon,
  etsy: etsy,
  paypal: paypal,
  stripe: stripe,
  revolut: revolut,
  adyen: adyen,
  typeform: typeform,
  itcloset: itcloset,
  vsco: vsco,
  '21buttons': _21buttons,
  vinted: vinted,
  peoople: peoople,
  opensea: opensea,
  behance: behance,
  dribbble: dribbble,
  onlyfans: onlyfans,
  flipboard: flipboard,
  tumblr: tumblr,
  rarible: rarible,
  menu: menu,
  discountcode: discountcode,
  micolet: micolet,
  customlink: customlink,
  affiliateLink: affiliatelink,
};
