
import styled from 'styled-components';
import Text from '../text';

const BodyBlack = styled(Text)`
  font-family: Lato;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 1.5;
`;

export {BodyBlack};
