import styled from 'styled-components';
import Container from '../Container';

const ContainerMain = styled(Container)`
  box-shadow: 4px 4px 34px -10px rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export {ContainerMain};