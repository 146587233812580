import styled from 'styled-components';
import {ButtonProps} from './button';

const StyledButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 0.7rem;
  padding-left: 3.3rem;
  padding-right: 3.3rem;
  cursor: ${({disabled}: ButtonProps) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({disabled}: ButtonProps) => (disabled ? 'none' : 'auto')};
  user-select: none;
  transition: all ease-in-out 400ms;
  -webkit-tap-highlight-color: transparent;
`;

export default StyledButton;
