import { ProfileColor } from "../modules/profile/types"
import {transparentize} from 'polished'

export const COLORS_OPTIONS = (): ProfileColor[] => {
  return [
    {
      base: "#FFFFFF",
      border: "#EDEDED",
      value: "#EDEDED",
    },
    {
      base: "#F7DADE",
      border: transparentize(0.4, "#CB041C"),
      value: "#CB041C",
    },
    {
      base: "#FFF5E6",
      border: transparentize(0.4, "#FF9D00"),
      value: "#FF9D00",
    },
    {
      base: "#FEFEF4",
      border: "#F7FC70",
      value: "#F3F6A0",
    },
    {
      base: "#F3FBEC",
      border: "#D0EFB7",
      value: "#D0EFB7",
    },
    {
      base: "#F1F9FF",
      border: "#73C4FF",
      value: "#73C4FF",
    },
    {
      base: transparentize(0.4, "#DEBFFC"),
      border: "#DEBFFC",
      value: "#DEBFFC",
    },
    {
      base: transparentize(0.4, "#F9C2E1"),
      border: "#F9C2E1",
      value: "#F9C2E1",
    },
  ]
}