import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { TextPresets, Container, ContainerPresets } from "../UI";

const NotFound = () => {
  return (
    <Container>
      <ContainerPresets.ContainerMain wrapperSize="sm">
        <Stack spacing={4}>
          <Skeleton variant="text" />
          <TextPresets.BodyBold>404 User Not Found</TextPresets.BodyBold>
          <Skeleton variant="rectangular" width={210} height={300} />
        </Stack>
      </ContainerPresets.ContainerMain>
    </Container>
  );
};

export default NotFound;
