export const ProfilePlan = {
  FREE: 'FREE',
  PRO: 'PRO',
} as const;
// eslint-disable-next-line
export type ProfilePlan = (typeof ProfilePlan)[keyof typeof ProfilePlan];


export interface ProfileValues {
  fullName: string;
  username: string;
  profileImage: string;
  city: string;
  country: string;
  biography?: string;
  language?: string;
  birthdate?: string;
  platforms: AvailableProfileSocialPlatforms[];
  platformsColor: string;
  privacy: ProfilePrivacy,
  plan: ProfilePlan;
}

export interface ProfileInstance extends ProfileValues {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProfileFollowingValues {
  notes: string;
}

export interface ProfileFollowingInstance extends ProfileFollowingValues {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProfileSocialPlatform {
  id: string;
  platformType: string;
  image?: string;
  description: string;
  order: number;
  featured?: boolean
  restrictions?: SocialPlatformCodeRestriction[]
}

export interface LinkSocialPlatform extends ProfileSocialPlatform {
  platformType: Exclude<SocialPlatformType, typeof UsernameSocialPlatformTypes[number] | typeof AddressSocialPlatformTypes[number] | typeof PhoneSocialPlatformTypes[number] | typeof ContactSocialPlatformTypes[number] | typeof EmailSocialPlatformTypes[number]>;
  url: string;
}

export interface UsernameSocialPlatform extends ProfileSocialPlatform {
  platformType: typeof UsernameSocialPlatformTypes[number];
  username: string;
}

export interface AddressSocialPlatform extends ProfileSocialPlatform {
  platformType: typeof AddressSocialPlatformTypes[number];
  address: string;
}

export interface PhoneSocialPlatform extends ProfileSocialPlatform {
  platformType: typeof PhoneSocialPlatformTypes[number];
  intlPrefix: string;
  localNumber: string;
}

export interface ContactSocialPlatform extends ProfileSocialPlatform {
  platformType: typeof ContactSocialPlatformTypes[number];
  name: string;
}

export interface EmailSocialPlatform extends ProfileSocialPlatform {
  platformType: typeof EmailSocialPlatformTypes[number];
  email: string;
}

export type AvailableProfileSocialPlatforms = LinkSocialPlatform | UsernameSocialPlatform | AddressSocialPlatform | PhoneSocialPlatform | ContactSocialPlatform | EmailSocialPlatform;

export const FollowingStatus = {
  BLOCKED: "BLOCKED",
  FOLLOWING: "FOLLOWING",
  REQUESTED: "REQUESTED",
  NOT_FOLLOWING: "NOT_FOLLOWING",
} as const;
// eslint-disable-next-line
export type FollowingStatus = typeof FollowingStatus[keyof typeof FollowingStatus];

export interface SocialPlatformInteraction {
  icon: any,
  clicks: number,
  title: string
}

export interface ProfileColor {
  base: string,
  border: string
  value: string,
}

export const ProfilePrivacy = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
}
// eslint-disable-next-line
export type ProfilePrivacy = typeof ProfilePrivacy[keyof typeof ProfilePrivacy];


export const EmptyProfileInstance = {
  id: "-",
  city: "",
  country: "",
  fullName: "",
  biography: "",
  language: "es",
  platforms: [],
  platformsColor: "#FFFFFF",
  profileImage: "",
  username: "",
  privacy: ProfilePrivacy.PUBLIC,
  plan: ProfilePlan.FREE,
  createdAt: new Date(Date.now()),
  updatedAt: new Date(Date.now()),
};

export const EmptyProfileFollowingInstance = {
  id: "-",
  notes: "",
  createdAt: new Date(Date.now()),
  updatedAt: new Date(Date.now()),
};

export const SocialPlatformType = {
  phone: 'phone',
  email: 'email',
  whatsapp: 'whatsapp',
  contact: 'contact',
  address: 'address',
  signal: 'signal',
  telegram: 'telegram',
  instagram: 'instagram',
  linkedin: 'linkedin',
  bereal: 'bereal',
  twitter: 'twitter',
  tiktok: 'tiktok',
  pinterest: 'pinterest',
  snapchat: 'snapchat',
  twitch: 'twitch',
  reddit: 'reddit',
  discord: 'discord',
  clubhouse: 'clubhouse',
  facebook: 'facebook',
  youtube: 'youtube',
  spotify: 'spotify',
  applemusic: 'applemusic',
  soundcloud: 'soundcloud',
  vimeo: 'vimeo',
  ivoox: 'ivoox',
  web: 'web',
  whatsappbusiness: 'whatsappbusiness',
  calendly: 'calendly',
  googledrive: 'googledrive',
  dropbox: 'dropbox',
  googlecalendar: 'googlecalendar',
  curriculum: 'curriculum',
  slack: 'slack',
  monday: 'monday',
  asana: 'asana',
  confluence: 'confluence',
  trello: 'trello',
  zoom: 'zoom',
  skype: 'skype',
  googlemeet: 'googlemeet',
  googlereviews: 'googlereviews',
  microsoftteams: 'microsoftteams',
  googlehangouts: 'googlehangouts',
  evernote: 'evernote',
  notion: 'notion',
  appstore: 'appstore',
  playstore: 'playstore',
  medium: 'medium',
  wetransfer: 'wetransfer',
  eventbrite: 'eventbrite',
  shopify: 'shopify',
  amazon: 'amazon',
  etsy: 'etsy',
  paypal: 'paypal',
  stripe: 'stripe',
  revolut: 'revolut',
  adyen: 'adyen',
  typeform: 'typeform',
  itcloset: 'itcloset',
  vsco: 'vsco',
  _21buttons: '21buttons',
  vinted: 'vinted',
  peoople: 'peoople',
  opensea: 'opensea',
  behance: 'behance',
  dribbble: 'dribbble',
  onlyfans: 'onlyfans',
  flipboard: 'flipboard',
  tumblr: 'tumblr',
  rarible: 'rarible',
  menu: 'menu',
  discountcode: 'discountcode',
  micolet: 'micolet',
  customlink: 'customlink',
  affiliateLink: 'affiliateLink',
} as const;
// eslint-disable-next-line
export type SocialPlatformType = (typeof SocialPlatformType)[keyof typeof SocialPlatformType];

export const UsernameSocialPlatformTypes = [
  SocialPlatformType.snapchat,
  SocialPlatformType.pinterest,
  SocialPlatformType.facebook,
  SocialPlatformType.tiktok,
  SocialPlatformType.instagram,
  SocialPlatformType.twitter,
] as const;

export const AddressSocialPlatformTypes = [SocialPlatformType.address] as const;

export const PhoneSocialPlatformTypes = [SocialPlatformType.phone, SocialPlatformType.whatsapp] as const;

export const ContactSocialPlatformTypes = [SocialPlatformType.contact] as const;

export const EmailSocialPlatformTypes = [SocialPlatformType.email] as const;

export const LinkSocialPlatformTypes = Object.values(SocialPlatformType).filter((item) => {
  const platformsToDelete: SocialPlatformType[] = [
    ...UsernameSocialPlatformTypes,
    ...AddressSocialPlatformTypes,
    ...PhoneSocialPlatformTypes,
    ...ContactSocialPlatformTypes,
    ...EmailSocialPlatformTypes,
  ];

  return !platformsToDelete.includes(item);
});

export interface SocialPlatformCodeRestriction {
  code: string,
  description: string,
}

export type FollowingMap = {[id: string]: FollowingStatus};