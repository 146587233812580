import {HTMLAttributes, PropsWithChildren} from 'react';
import styled from 'styled-components';

const StyledText = styled.span`
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
`;

export interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  disabled?: boolean;
}

export const Text = ({children, ...props}: PropsWithChildren<TextProps>) => {
  return <StyledText {...props}>{children}</StyledText>;
};

export default Text;
