
import styled from 'styled-components';
import Text from '../text';

const BodyBoldSmall = styled(Text)`
  font-family: Lato;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.5;
`;

export {BodyBoldSmall};
