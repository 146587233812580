import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { useParams } from "react-router-dom";
import { AddressSocialPlatform, AddressSocialPlatformTypes, AvailableProfileSocialPlatforms, ContactSocialPlatformTypes, EmailSocialPlatform, EmailSocialPlatformTypes, EmptyProfileInstance, LinkSocialPlatform, PhoneSocialPlatform, PhoneSocialPlatformTypes, ProfileInstance, SocialPlatformCodeRestriction, SocialPlatformType, UsernameSocialPlatform, UsernameSocialPlatformTypes } from "../../modules/profile/types";
import { GetProfileByUsernameError, getProfileByUsername } from "../../api/server/getProfileByUsername";
import { LoadingStyles } from "./ProfilePageLoading.styles";
import { ErrorStyles } from "./ProfilePageError.styles";
import { MainStyles, ModalStyles } from "./ProfilePage.styles";
import { FadeLoader } from "react-spinners";
import { Skeleton, Stack } from "@mui/material";
import { TextPresets } from "../../components/UI";
import defaultProfile from "../../assets/Images/imgProfileEmpty.png";
import proImage from "../../assets/Icons/pro.png";
import lockIcon from '../../assets/Icons/lock-outlined-dark.png'
import restrictionIcon from '../../assets/Icons/lock-locked-outlined-dark.png'
import logoImage from '../../assets/Images/cirqel-logo-dark.png';
import webImage from "../../assets/platforms/web.png";
import { PLATFORM_OPTIONS } from "../../selectors/platforms";
import { useTranslation } from "react-i18next";
import { checkPlatform } from "../../helpers/checkPlatform";
import { COLORS_OPTIONS } from "../../selectors/colors";

Modal.setAppElement('#root');

export function ProfilePage() {
  const [t] = useTranslation();

  const { username } = useParams();

  const [loadingProfile, setLoadingProfile] = useState(false);
  const [profile, setProfile] = useState<ProfileInstance>(EmptyProfileInstance);
  const [profileError, setProfileError] = useState<GetProfileByUsernameError | null>(null);

  const [platformOnTest, setPlatformOnTest] = useState<AvailableProfileSocialPlatforms | null>(null);
  const [restrictionOnTest, setRestrictionOnTest] = useState<SocialPlatformCodeRestriction | null>(null);
  const [restrictionCode, setRestrictionCode] = useState("");
  const [restrictionError, setRestrictionError] = useState("");

  const colors = COLORS_OPTIONS();

  useEffect(() => {
    (async () => {
      const result = await getProfileByUsername(username ?? "");

      if (result.isFailure) {
        setProfile(EmptyProfileInstance);
        setProfileError(result.failure);
        setLoadingProfile(false);
        return;
      }

      setProfile(result.success);
      setProfileError(null);
      setLoadingProfile(false);
    })();
  }, [username]);

  const goToShopHandler = () => {
    const url = checkPlatform();

    window.location.href = url;
  }

  const isProProfile = profile.plan === "PRO";

  useEffect(() => {
    if(!restrictionError){
      return;
    }

    const timeoutId = setTimeout(() => {
      setRestrictionError("");
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [restrictionError]);

  const clickPlatformHandler = (platform: AvailableProfileSocialPlatforms) => {
    if(UsernameSocialPlatformTypes.includes(platform.platformType as typeof UsernameSocialPlatformTypes[number])){
      const usernamePlatform = platform as UsernameSocialPlatform
      switch(platform.platformType){
        case SocialPlatformType.snapchat:
          window.location.href = `https://snapchat.com/${usernamePlatform.username}`
          return;
        case SocialPlatformType.pinterest:
          window.location.href = `https://pinterest.com/${usernamePlatform.username}`
          return;
        case SocialPlatformType.facebook:
          window.location.href = `https://facebook.com/${usernamePlatform.username}`
          return;
        case SocialPlatformType.tiktok:
          window.location.href = `https://tiktok.com/@${usernamePlatform.username}`
          return;
        case SocialPlatformType.instagram:
          window.location.href = `https://instagram.com/${usernamePlatform.username}`
          return;
        case SocialPlatformType.twitter:
          window.location.href = `https://twitter.com/${usernamePlatform.username}`
          return;
      }
    }else if(AddressSocialPlatformTypes.includes(platform.platformType as typeof AddressSocialPlatformTypes[number])){
      const addressPlatform = platform as AddressSocialPlatform
      window.location.href = `https://maps.google.com/?q=${encodeURI(addressPlatform.address)}`
    }else if(PhoneSocialPlatformTypes.includes(platform.platformType as typeof PhoneSocialPlatformTypes[number])){
      const phonePlatform = platform as PhoneSocialPlatform

      const formattedPrefix = (phonePlatform.intlPrefix ?? "").replaceAll("+", "");

      if(SocialPlatformType.whatsapp === platform.platformType){
        window.location.href = `https://wa.me/${formattedPrefix}${phonePlatform.localNumber ?? ""}`
        return;
      }

      window.location.href = `tel:+${formattedPrefix}${phonePlatform.localNumber ?? ""}`
    }else if(ContactSocialPlatformTypes.includes(platform.platformType as typeof ContactSocialPlatformTypes[number])){
      
    }else if(EmailSocialPlatformTypes.includes(platform.platformType as typeof EmailSocialPlatformTypes[number])){
      window.location.href = `mailto:${(platform as EmailSocialPlatform).email}`
    }else {
      window.location.href = (platform as LinkSocialPlatform).url;
    }
  }

  const evaluateRestrictionCode = () => {
    if(!platformOnTest){
      setRestrictionError("No hay una plataforma seleccionada");
      return;
    }

    if(restrictionOnTest?.code !== restrictionCode){
      setRestrictionError("El código introducido es erróneo");
      return;
    }

    clickPlatformHandler(platformOnTest);
  }

  const closeModalHandler = () => {
    setPlatformOnTest(null);
    setRestrictionOnTest(null);
    setRestrictionCode("")
    setRestrictionError("")
  }

  const SocialPlatformsContent = (() => {
    if(profile.privacy === "PRIVATE"){
      return (
        <div className="Profile__Content-private">
          <div className="Profile__Content-private-image">
            <img className="Profile__Content-private-image-content" src={lockIcon} alt="Cuenta privada"/>
          </div>
          <div className="Profile__Content-private-title">
            <span className="Profile__Content-private-title-content">Esta cuenta es privada</span>
          </div>
          <div className="Profile__Content-private-subtitle">
            <span className="Profile__Content-private-subtitle-content">Descarga la aplicación y sigue a su perfil</span>
          </div>
        </div>
      )
    }

    const platforms = PLATFORM_OPTIONS({t});

    const sortedPlatforms = [...profile.platforms].sort((a, b) => a.order - b.order);

    return (
      <div className="Profile__Content-public">
        {sortedPlatforms.map(platform => {
          const image = platform.image ?? platforms.find(item => item.platformType === platform.platformType)?.image ?? webImage;

          const featuredStatusClass = platform.featured ? "Profile__SocialPlatform-featured" : "Profile__SocialPlatform-not-featured";

          const platformColor = colors.find(item => item.value === profile.platformsColor);
          const withRestriction = platform.restrictions && platform.restrictions.length >= 1 && platform.restrictions[0];

          const platformPressHandler = () => {
            if(withRestriction){
              setRestrictionOnTest(withRestriction);
              setPlatformOnTest(platform);
              return;
            }

            clickPlatformHandler(platform);
          }

          return (
            <div key={platform.id} className={`Profile__SocialPlatform ${featuredStatusClass}`} onClick={platformPressHandler} style={{backgroundColor: platformColor?.base ?? undefined, borderColor: platformColor?.border ?? undefined}}>
              <div className="Profile__SocialPlatform-image">
                <img src={image} alt="" className="Profile__SocialPlatform-image-content"/>
              </div>
              <div className="Profile__SocialPlatform-description">
                <span className="Profile__SocialPlatform-description-content">
                  {platform.description}
                </span>
              </div>
              {
                withRestriction ? (
                  <div className="Profile__SocialPlatform-restricted">
                    <img src={restrictionIcon} alt="Restringido" className="Profile__SocialPlatform-restricted-image"/>
                  </div>
                ) : null
              }
            </div>
          )
        })}
      </div>
    );
  })();

  if (loadingProfile) {
    return (
      <LoadingStyles>
        <div className="Container">
          <FadeLoader width={5} margin={10} color="#2C64E3" />
        </div>
      </LoadingStyles>
    );
  }

  if (profileError) {
    switch (profileError) {
      case GetProfileByUsernameError.INVALID_REQUEST:
        return (
          <ErrorStyles>
            <div className="Container">
              <Stack spacing={4} className="Container__Content">
                <Skeleton variant="text" className="FullWidth" />
                <TextPresets.BodyBold className="Container__Content-title">La petición ha sido inválida</TextPresets.BodyBold>
                <Skeleton variant="rectangular" width={210} height={300} />
              </Stack>
            </div>
          </ErrorStyles>
        );
      case GetProfileByUsernameError.PROFILE_NOT_FOUND:
        return (
          <ErrorStyles>
            <div className="Container">
              <Stack spacing={4} className="Container__Content">
                <Skeleton variant="text" className="FullWidth" />
                <TextPresets.BodyBold className="Container__Content-title">Usuario no encontrado</TextPresets.BodyBold>
                <Skeleton variant="rectangular" width={210} height={300} />
              </Stack>
            </div>
          </ErrorStyles>
        );
      case GetProfileByUsernameError.UNKNOWN:
      default:
        return (
          <ErrorStyles>
            <div className="Container">
              <Stack spacing={4} className="Container__Content">
                <Skeleton variant="text" className="FullWidth" />
                <TextPresets.BodyBold className="Container__Content-title">Ha ocurrido un error desconocido</TextPresets.BodyBold>
                <Skeleton variant="rectangular" width={210} height={300} />
              </Stack>
            </div>
          </ErrorStyles>
        );
    }
  }

  return (
    <>
    <MainStyles>
      <div className="Container">
        <header className="Profile__Header">
          <div className="Profile__Header-image">
            <img className="Profile__Header-image-profile" src={profile.profileImage || defaultProfile} alt={profile.fullName}/>
            {isProProfile ? <img className="Profile__Header-image-pro" src={proImage} alt={profile.fullName}/> : null}
          </div>
          <div className="Profile__Header-title">
            <h1 className="Profile__Header-title-text">{profile.fullName}</h1>
          </div>
          <div className="Profile__Header-subtitle">
            <span className="Profile__Header-subtitle-text">{profile.biography}</span>
          </div>
        </header>
        <main className="Profile__Content">
          {SocialPlatformsContent}
        </main>
        <footer className="Profile__Footer">
          <div className="Profile__Footer-logo" onClick={goToShopHandler}>
            <img className="Profile__Footer-logo-content" alt="CIRQEL" src={logoImage}/>
          </div>
        </footer>
      </div>
    </MainStyles>
    <Modal isOpen={!!restrictionOnTest} shouldCloseOnEsc shouldCloseOnOverlayClick onRequestClose={closeModalHandler} style={customModalStyles}
>
      <ModalStyles>
        <div className="Header">
          <span className="Header__Title">Introduce el código de acceso</span>
          {restrictionOnTest?.description ? <span className="Header__Description">{restrictionOnTest.description}</span> : null}
        </div>
        <div className="Form">
          <div className="Form__Input">
            <label className="Form__Input-label">Código</label>
            <input className="Form__Input-input" type="password" value={restrictionCode} onChange={(e) => setRestrictionCode(e.target.value)}/>
          </div>
          <div className="Button" onClick={evaluateRestrictionCode}>
            <span className="Button__Label">Entrar</span>
          </div>
        </div>
        {restrictionError ? <div className="Result">
          <p className="Result__Error">{restrictionError}</p>
        </div> : null}
      </ModalStyles>
    </Modal>
    </>
  );
}

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}