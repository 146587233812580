import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { Container } from "../container";
import { HeaderProps } from "./Header";

export const StyledContainer = styled(Container)`
  ${({ sticky, stickyBottom }: HeaderProps) => (sticky || stickyBottom ? "position: fixed;" : "")}

  ${({ sticky }: HeaderProps) => (sticky ? "top: 0;" : "")}
  ${({ stickyBottom }: HeaderProps) => (stickyBottom ? "bottom: 0;" : "")} /* z-index: 11; */
  position:absolute
`;

const StyledHeader = styled.div`
  /* background-color: ${Colors.Grayscale.FullLight}; */
  padding: 1.5rem 0;
  /* box-shadow: 0 2px 8px #b8b8b83f; */

  ._flex {
    display: flex;
    align-items: center;
  }

  ._title {
    text-align: center;
    flex: 1;
    user-select: none;
  }

  ._left {
    user-select: none;
    text-align: left;
    flex: 1;
    text-decoration: underline;
  }

  ._right {
    user-select: none;
    text-align: right;
    flex: 1;
    text-decoration: underline;
  }

  ._side {
    display: flex;
    flex: 0.5;
    cursor: pointer;

    &_left {
      padding-left: 2rem;
      -webkit-tap-highlight-color: transparent;
    }

    &_right {
      padding-right: 2rem;
      justify-content: end;
      -webkit-tap-highlight-color: transparent;
    }
  }

  ._center {
    display: flex;
    flex: 10;
  }
`;

export default StyledHeader;
