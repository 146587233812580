import styled from "styled-components";

export const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    box-shadow: 4px 4px 34px -10px rgba(0,0,0,0.25);

    &__Content{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px 20px;
    }
  }
`;