import {HTMLAttributes, PropsWithChildren} from 'react';
import {TextPresets} from '../text';
import StyledButton from './button.styles';

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

export const Button = ({children, ...props}: PropsWithChildren<ButtonProps>) => {
  if (typeof children === 'string') {
    return (
      <StyledButton {...props}>
        <TextPresets.BodyBoldSmall>{children}</TextPresets.BodyBoldSmall>
      </StyledButton>
    );
  }

  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
