import { AxiosError } from "axios";
import { Result } from "../../helpers/Result";
import { ServerResponse } from "./types";
import { ProfileInstance } from "../../modules/profile/types";
import { serverClient } from "./client";

export const GetProfileByUsernameError = {
  INVALID_REQUEST: "INVALID_REQUEST",
  UNKNOWN: "UNKNOWN",
  PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
} as const;
// eslint-disable-next-line
export type GetProfileByUsernameError = typeof GetProfileByUsernameError[keyof typeof GetProfileByUsernameError];

export async function getProfileByUsername(username: string): Promise<Result<ProfileInstance, GetProfileByUsernameError>>{
  try{
    const response = await serverClient.post<ServerResponse<ProfileInstance>>(`/profile/search/username`, {
      username,
    });

    return Result.ok(response.data.payload)
  }catch(_error){
    const error = _error as AxiosError<ServerResponse<GetProfileByUsernameError>>;

    if(!error.response){
      console.error(error.toJSON())
      return Result.fail(GetProfileByUsernameError.UNKNOWN)
    }
    const data = error.response?.data as ServerResponse<GetProfileByUsernameError>

    return Result.fail(data.payload);
  }
}