
import styled from 'styled-components';
import Text from '../text';

const BodyLight = styled(Text)`
  font-family: Lato;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5;
`;

export {BodyLight};
