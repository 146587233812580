import { darken, lighten } from "polished";

const Colors = {
  Grayscale: {
    FullDark: "#101010",
    Dark: "#141414",
    DarkTranslucid: `#14141480`,
    HoveredFullDark: lighten(0.08, "#101010"),
    HoveredDark: lighten(0.1, "#141414"),
    Gray: "#8A8A8A",
    Silver: "#B8B8B8",
    GrayLight: "#DBDBDB",
    HoveredLight: darken(0.1, "#FAFAFA"),
    HoveredFullLight: darken(0.08, "#FFFFFF"),
    LightTranslucid: `#FAFAFA80`,
    Light: `#FAFAFA`,
    FullLight: `#FFFFFF`,
  },
  Status: {
    Disabled: "darkgrey",
    DisabledLight: "#EDEDED",
    Inactive: "#E6E6E6",
    InactiveSecondary: "#B8B8B8",
    Active: "#1565C0",
    Danger: "#E93F00",
    Info: "#1565C0",
  },
  SocialMedia: {
    CallMe: "#FFFFFF",
    TikTok: "#FFFFFF",
    Whatsapp: "#25D366",
    Linkedin: "#0077B5",
    Facebook: "#1877F2",
    Twitter: "#55ACEE",
    Email: "#FFFFFF",
    Instagram:
      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
  },
};

export default Colors;
