import Colors from '../../../../assets/Colors';
import styled from 'styled-components';
import Button, {ButtonProps} from '../button';

export const ButtonRoundedOutlined = styled(Button)`
  border: none;
  background-color: ${({disabled}: ButtonProps) => (disabled ? Colors.Status.DisabledLight : Colors.Grayscale.FullLight)};
  color: ${({disabled}: ButtonProps) => (disabled ? Colors.Grayscale.DarkTranslucid : Colors.Status.Active)};

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  padding: 0.7rem 7rem;
  border-radius: 5rem;
  -webkit-tap-highlight-color: transparent;
  
  :hover {
    background-color: ${Colors.Grayscale.HoveredFullLight};
  }
`;
