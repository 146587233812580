import Colors from '../../../../assets/Colors';
import styled from 'styled-components';
import Button, {ButtonProps} from '../button';
import { TextPresets } from '../../text';

export const StyledButton = styled(Button)`
  border-color: ${({disabled}: ButtonProps) => (disabled ? Colors.Status.Disabled : Colors.Grayscale.FullLight)};
  background-color: ${({disabled}: ButtonProps) => (disabled ? Colors.Status.Disabled : Colors.Grayscale.FullLight)};
  color: ${Colors.Grayscale.Dark};
  -webkit-tap-highlight-color: transparent;

  :hover {
    background-color: ${Colors.Grayscale.HoveredFullLight};
    border-color: ${Colors.Grayscale.HoveredFullLight};
  }
`;

export interface ButtonPrimary extends ButtonProps {
  label: string;
}

export const ButtonPrimary = ({label, ...props}: ButtonPrimary) => {
  return (
    <StyledButton {...props}>
      <TextPresets.Body>{label}</TextPresets.Body>
    </StyledButton>
  );
};
