import { Routes, Route } from "react-router-dom";
import NotFound from "../components/ContactPage/NotFound";
import { StoriesPage } from "../views/StoriesPage";
import { ProfilePage } from "../views/ProfilePage/ProfilePage";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<NotFound />} />
      <Route path="/:username" element={<ProfilePage />} />
      <Route path="/stories/:uuid/:device" element={<StoriesPage />} />
    </Routes>
  );
};
