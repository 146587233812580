
import styled from 'styled-components';
import Text from '../text';

const Body = styled(Text)`
  font-family: Lato;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
`;

export {Body};
