import {HTMLAttributes, ReactNode} from 'react';
import StyledContainer from './Container.styles';
import classNames from 'classnames';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: string | ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  wrapperSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const Container = ({children, size, wrapperSize, className, ...props}: ContainerProps) => {
  return (
    <StyledContainer className={classNames({[`container container-${size}`]: !!size}, {[`wrapper-${wrapperSize}`]: !!wrapperSize}, className)} {...props}>
      {children}
    </StyledContainer>
  );
};

export default Container;