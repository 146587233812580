
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Stories from 'react-insta-stories';
import { Action, Story } from 'react-insta-stories/dist/interfaces';
import { ButtonPresets, Container, TextPresets } from '../components/UI';
import Colors from '../assets/Colors';

interface StoriesInterface {
    action: Action,
    story: Story,
    isPaused: boolean,
    config: {
        width?: number | string | undefined;
        height?: number | string | undefined;
        loader?: JSX.Element | undefined;
        header?: Function | undefined;
        storyStyles?: Object | undefined;
    },
    messageHandler: (type: string, data: any) => void,
    setIndex: (index: number) => void
}

export const StoriesPage = () => {

    const { uuid, device } = useParams();
    
    const [currentIndex, setCurrentIndex] = useState(0)

    console.log(uuid, device);

    const handlerOnClick = () => {

        switch(currentIndex) {
            case 1:
            case 3: 
                window.location.assign('cirqel://Login?deviceType=card/uuid=12345678');
                break;
            case 2:
                window.location.assign('https://play.google.com/store/apps/details?id=com.cirqel');
                break;
        }

    }

    return (
        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
            <Stories
                stories={[
                    {
                        content: (props) => <FirstStorie {...props} setIndex={setCurrentIndex} />
                    },
                    {
                        content: (props) => <SecondStorie {...props} setIndex={setCurrentIndex} />
                    },
                    {
                        content: (props) => <ThirdStorie {...props} setIndex={setCurrentIndex} />
                    }
                ]}
                defaultInterval={8000}
                preventDefault={false}
                width={window.innerWidth}
                height={window.innerHeight}
                storyContainerStyles={{ zIndex: 1 }}
                progressContainerStyles={{ marginTop: '5%', background: 'transparent', }}
                progressWrapperStyles={{ background: Colors.Grayscale.GrayLight, height: 5, borderRadius: 20, boxShadow: '0, 0, 0, 0' }}
            />
            <ButtonPresets.ButtonRoundedOutlined 
                style={{ position: 'absolute', width: '90%', alignSelf: 'center', bottom: '5%', zIndex: 3 }}
                onClick={handlerOnClick}>
                { currentIndex === 2 ? 'Descargar' : 'Activar' }
            </ButtonPresets.ButtonRoundedOutlined>
        </Container>
)
}

function FirstStorie({ action, isPaused, story, config, setIndex }: StoriesInterface) {

    useEffect(() => {
        setIndex(1)
    }, [])

    return (
        <Container style={{ background: '#1E65D0', width: '100%', height: '100%', padding: '20% 5% 5% 5%', alignItems: 'center', display: 'flex', flexDirection: 'column', }}>
            <Container style={{ display: 'flex', flexDirection: 'column', paddingInline: '5%',  }}>
                <TextPresets.BodyBlack style={{ color: '#FFF', textAlign: 'left', fontSize: '3rem' }}>
                    CIRQEL Dot
                </TextPresets.BodyBlack>
                <TextPresets.Body style={{ color: '#FFF', marginTop: '5%', fontSize: '1.6rem' }}>
                    Este Dot aún no ha sido activado. <br /> ¿Quieres activarlo ahora?
                </TextPresets.Body>
            </Container>

            <img 
                src={require('../assets/Images/Tag-2.png')}
                style={{ position: 'absolute', bottom: '30%', marginTop: '10%' }}       
            />
            
            <Container style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '15%', left: '10%', right: '5%' }}>
                <TextPresets.Body style={{ color: '#FFF', marginRight: '2%', fontWeight: '700', fontSize: '1.6rem' }}>
                    Desliza para ver cómo hacerlo
                </TextPresets.Body>
                <img 
                    src={require('../assets/Images/arrow.png')}
                />
            </Container>
        </Container>
    )

}

function SecondStorie({ action, isPaused, story, config, setIndex }: StoriesInterface) {

    useEffect(() => {
        setIndex(2)
    }, [])

    return (
        <Container style={{ background: '#1E65D0', width: '100%', height: '100%', padding: '20% 5% 5% 5%', alignItems: 'center', display: 'flex', flexDirection: 'column', }}>
            <Container style={{ display: 'flex', flexDirection: 'column', paddingInline: '5%',  }}>
                <TextPresets.BodyBlack style={{ color: '#FFF', textAlign: 'left', fontSize: '3rem' }}>
                    Descarga la app
                </TextPresets.BodyBlack>
                <TextPresets.Body style={{ color: '#FFF', marginTop: '5%', fontSize: '1.6rem' }}>
                    Si aún no tienes nuestra app, descárgatela <br /> para poder activar tu dispositivo.
                </TextPresets.Body>
            </Container>

            <img 
                src={require('../assets/Images/iphone.png')}
                style={{ position: 'absolute', bottom: '15%', marginTop: '10%' }}       
            />
        </Container>
    )

}

function ThirdStorie({ action, isPaused, story, config, setIndex }: StoriesInterface) {

    useEffect(() => {
        setIndex(3)
    }, [])

    return (
        <Container style={{ background: '#1E65D0', width: '100%', height: '100%', padding: '20% 5% 5% 5%', alignItems: 'center', display: 'flex', flexDirection: 'column', }}>
            <Container style={{ display: 'flex', flexDirection: 'column', paddingInline: '5%',  }}>
                <TextPresets.BodyBlack style={{ color: '#FFF', textAlign: 'left', fontSize: '3rem' }}>
                    Activa tu dispositivo
                </TextPresets.BodyBlack>
                <TextPresets.Body style={{ color: '#FFF', marginTop: '5%', fontSize: '1.6rem' }}>
                    ¿Te has descargado la app? Activa<br /> ahora tu nuevo dispositivo
                </TextPresets.Body>
            </Container>

            <img 
                src={require('../assets/Images/verify.png')}
                style={{ position: 'absolute', bottom: '30%', marginTop: '10%', width: '50%' }}       
            />
        </Container>
    )

}
