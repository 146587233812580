import React from "react";
import DialogMaterial, { DialogProps } from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(DialogMaterial)(() => ({
  root: {
    zIndex: "400 !important",
  },
  "& .MuiDialog-container": {
    alignItems: "end",
  },
  "& .MuiDialog-paper": {
    minWidth: "32rem",
  },
}));

export const Dialog = ({ open, children, ...props }: DialogProps) => {
  return (
    <StyledDialog open={open} TransitionComponent={Transition} keepMounted {...props}>
      {children}
    </StyledDialog>
  );
};

export default Dialog;
