import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    @media (min-width: 576px) {
      &-sm {
        max-width: 540px;
      }
    }

    @media (min-width: 768px) {
      &-sm,
      &-md {
        max-width: 720px;
      }
    }

    @media (min-width: 992px) {
      &-sm,
      &-md,
      &-lg {
        max-width: 960px;
      }
    }

    @media (min-width: 1200px) {
      &-sm,
      &-md,
      &-lg,
      &-xl {
        max-width: 1140px;
      }
    }

    @media (min-width: 1400px) {
      &-sm,
      &-md,
      &-lg,
      &-xl,
      &-xxl {
        max-width: 1320px;
      }
    }
  }

  &.wrapper {
    @media (min-width: 576px) {
      &-sm {
        max-width: 540px;
      }
    }

    @media (min-width: 768px) {
      &-md {
        max-width: 720px;
      }
    }

    @media (min-width: 992px) {
      &-lg {
        max-width: 960px;
      }
    }

    @media (min-width: 1200px) {
      &-xl {
        max-width: 1140px;
      }
    }

    @media (min-width: 1400px) {
      &-xxl {
        max-width: 1320px;
      }
    }
  }
`;

export default StyledContainer;
