import { darken } from "polished";
import styled from "styled-components";

export const MainStyles = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .Container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    box-shadow: 4px 4px 34px -10px rgba(0,0,0,0.25);
    background-color: #FFFFFF;
  }

  .Profile{
    &__Header {
      display: flex;
      flex-direction: column;
      padding: 24px 16px;
      flex: 0;

      &-image {
        height: 88px;
        width: 88px;
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        
        &-profile {
          border-radius: 50%;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        &-pro {
          height: 20px;
          width: 20px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &-title {

        &-text {
          font-size: 26px;
          font-weight: 500;
        }
      }

      &-subtitle {

        &-text {
          font-size: 16px;
          font-weight: 400;
          color: rgba(60, 60, 67, 0.8);
        }
      }
    }

    &__Content {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-private {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 16px;

        &-image {
          height: 82px;
          width: 82px;
          margin: 16px 0;
          text-align: center;

          &-content {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
        }

        &-title{
          margin: 4px 0;
          text-align: center;

          &-content{
            font-size: 18px;
            font-weight: 500;
            color: #051C2C;
          }
        }

        &-subtitle{
          margin: 4px 0;
          text-align: center;

          &-content{
            font-size: 14px;
            font-weight: 400;
            color: rgba(60, 60, 67, 0.8);
          }
        }
      }

      &-public {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        padding: 16px;
      }
    }

    &__SocialPlatform {
      background-color: #FFFFFF;
      border-radius: 15px;
      display: flex;
      box-shadow: 0 6px 30px rgba(0, 0, 0, 0.04);
      border: 1px solid #EDEDED;
      padding: 8px;
      cursor: pointer;
      position: relative;

      &-restricted {
        position: absolute;
        top: 2.7rem;
        right: 1.7rem;

        &-image {
          height: 2rem;
          width: 2rem;
        }
      }

      &-not-featured {
        flex-direction: column;
        min-height: 154px;
      }

      &-featured {
        flex-direction: row;
        grid-column: span 2;
        min-height: 74px;
      }

      &-image {
        height: 42px;
        width: 42px;
        margin: 8px;
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.04);
        border: 1px solid #EDEDED;
        border-radius: 13px;
        
        &-content {
          width: 100%;
          height: auto;
          border-radius: 12px;
        }
      }

      &-description {
        padding: 8px;

        &-content {
          font-size: 16px;
          font-weight: 400;
          color: #051C2C;
        }
      }
    }

    &__Footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 56px 16px;
      flex: 0;

      &-logo {
        width: 103px;
        cursor: pointer;

        &-content {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;

export const ModalStyles = styled.div`
  display: flex;
  flex-direction: column;

  .Header{
    display: flex;
    flex-direction: column;

    &__Title{
      display: inline-block;
      color: #000000;
      font-size: 1.6rem;
      font-weight: 500;
      margin: 0.8rem 0;
    }

    &__Description{
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 400;
      color: #051c2c;
      margin: 0.8rem 0;
    }
  }

  .Form {

    &__Input {
      display: flex;
      flex-direction: column;
      border: 1px solid #D5D5D5;
      border-radius: 1.2rem;
      padding-bottom: 1.2rem;
      margin: 1.2rem 0;

      &-label{
        padding-top: 1.2rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-bottom: 0.8rem;
        color: #3C3C43;
        font-size: 1.4rem;
      }

      &-input{
        margin-left: 1.6rem;
        margin-right: 1.6rem;
      }
    }
  }

  .Button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2C64E3;
    transition: all ease-in-out 400ms;
    padding: 1.2rem;
    border-radius: 1.2rem;
    cursor: pointer;

    &:hover {
      background-color: ${darken(.05, "#2C64E3")};
    }

    &__Label {
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  .Result {
    margin: 1.6rem 0;

    &__Error {
      font-size: 1.6rem;
      font-weight: 400;
      color: #DE330D;
    }
  }
`;