import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import es from "./es.json";

export const defaultNS = "app";

export const resources = {
  en,
  es,
};

function getLang() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language.substring(0, 2);
}

const locale = getLang();
i18n.use(initReactI18next).init({
  lng: locale,
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  defaultNS,
  resources,
});

export default i18n;
